import type { Validation } from "@vuelidate/core";
import get from "lodash/get";
import has from "lodash/has";

export const getValidatorFieldProps = (validator: Validation, fieldPath: string) => {
	if (!has(validator, fieldPath)) {
		console.warn(`Validator for field '${fieldPath}' not found`);
		return {};
	}

	return {
		error: get(validator, fieldPath).$error,
		help: get(validator, fieldPath).$errors.map((e: any) => e.$message)[0],
		onBlur: () => get(validator, fieldPath).$touch(),
		success: get(validator, fieldPath).$dirty && !get(validator, fieldPath).$error
	};
};
